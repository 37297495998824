.membership-infoClassic {
  max-width: 1220px;
  margin: 0 auto;
  padding: 30px;
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.membership-titleClassic {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.membership-infoClassic p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333; /* Ensure text color is set for normal text */
}

.membership-subtitleClassic {
  font-size: 1.3em;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.membership-listClassic {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.membership-listClassic li {
  text-align: justify;
  margin-bottom: 10px;
}

.membership-offerClassic {
  margin: 20px 0;
  font-size: 16px;
}

.membership-detailClassic,
.membership-instructionsClassic {
  font-size: 16px;
}

.membership-stepsClassic {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.membership-stepsClassic li {
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .membership-infoClassic {
    padding: 20px;
  }

  .membership-titleClassic {
    font-size: 2em;
  }

  .membership-infoClassic p {
    font-size: 14px;
  }

  .membership-subtitleClassic {
    font-size: 1.2em;
  }
}

/* CSS for clickable links */
.membership-infoClassic .clickable-link {
  color: blue !important; /* Ensure link color is blue and override other styles */
  text-decoration: underline !important;
  cursor: pointer !important;
}

.membership-infoClassic .clickable-link:hover {
  color: darkblue !important; /* Darker color on hover */
}
