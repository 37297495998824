.fiva-letter-description-container {
  font-family: "Times New Roman", serif;
  max-width: 1220px;
  margin: 40px auto;
  padding: 20px;
  color: #333;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.fiva-letter-description-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 50px;
}

.fiva-letter-description-container p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 16px;
}

.fiva-letter-description-container a {
  color: #b34444;
  text-decoration: underline;
}

.fiva-letter-description-container a:hover {
  color: #551a8b;
}

.document-link-container {
  text-align: center;
  margin-top: 20px;
}

.fiva-letter-description-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  color: red;
}

.fiva-letter-description-container h2::before {
  content: "▩";
  font-size: 24px;
  color: red;
  margin-right: 8px;
  vertical-align: middle;
}

.fiva-letter-description-container ul {
  padding-left: 20px;
}

.fiva-letter-description-container li {
  margin-bottom: 10px;
}
