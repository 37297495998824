.membership-infoCouncil {
  max-width: 1220px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.membership-titleCouncil {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 50px;
}

.membership-subtitleCouncil {
  font-size: 1.8em;
  font-weight: bold;
  color: #b34444;
  text-align: center;
  margin-bottom: 20px;
}

.membership-listCouncil {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.membership-listCouncil li {
  font-size: 1.2em;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .membership-titleCouncil {
    font-size: 2em;
  }

  .membership-subtitleCouncil {
    font-size: 1.5em;
  }

  .membership-infoCouncil {
    padding: 15px;
  }

  .membership-listCouncil li {
    font-size: 1em;
  }
}
