.product-info-card-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

.product-info-card {
  text-align: center;
}

.product-info-img-display {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 20px;
}

.product-info-img-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: transparent; /* No background */
  color: white;
  border: none;
  padding: 10px;
  z-index: 10;
  font-size: 3em; /* Increase font size for larger arrows */
  font-weight: bold; /* Make the arrow thicker */
}



.product-info-img-showcase {
  max-width: 100%;
  height: auto;
  display: block;
}

.product-info-img-select {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.product-info-img-item img {
  width: 50px;
  cursor: pointer;
}

.product-info-content {
  text-align: left;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.product-info-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.product-info-description,
.product-info-price,
.product-info-contact {
  margin-bottom: 1em;
}

.product-info-contact a {
  color: #007BFF;
  text-decoration: none;
}

.product-info-contact a:hover {
  text-decoration: underline;
}

.product-info-other-products {
  margin-top: 40px;
}

.product-info-product-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;
  display: inline-block;
  margin: 10px;
}

.product-info-product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-info-product-image {
  width: 100%;
  height: auto;
  display: block;
}

.product-info-product-details h2 {
  padding: 10px;
  background-color: #f8f8f8;
  margin: 0;
}

@media (max-width: 768px) {
  .product-info-img-nav {
    font-size: 2em; /* Adjust font size for smaller screens */
    padding: 5px; /* Adjust padding for smaller screens */
  }

  .product-info-img-nav.prev {
    left: -60px; /* Adjust positioning for smaller screens */
  }

  .product-info-img-nav.next {
    right: -60px; /* Adjust positioning for smaller screens */
  }

  .product-info-card-wrapper {
    margin-top: 60px;
  }
}
