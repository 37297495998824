.membership-info-reg {
  max-width: 1220px;
  margin: 50px auto;
  font-family: "Helvetica Neue", sans-serif;
  background: #ffffff;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.membership-subtitle-reg,
.membership-subtitles {
  font-size: 2.5em;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}

.membership-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.membership-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(30% - 20px);
  overflow: hidden;
  text-align: center;
}
.new-members,
.how-to-join {
  text-align: center;
}
.membership-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 20px;
}

.membership-type-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.membership-note {
  font-style: italic;
  color: #a83232;
  margin-top: -5px;
  margin-bottom: 30px;
}

.join-button {
  background-color: #d72631; 
  color: white; 
  padding: 15px 30px; 
  border: 2px solid transparent; 
  cursor: pointer; 
  display: block; 
  width: fit-content; 
  margin: 50px auto; 
  font-size: 1.1em; 
  font-weight: bold;
  border-radius: 30px; 
  transition: background-color 0.3s ease, transform 0.3s ease; 
  text-transform: uppercase; 
  letter-spacing: 1px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  outline: none; 
  position: relative; 
  overflow: hidden;
}

.join-button:hover,
.join-button:focus {
  background-color: #bf202e;
  transform: translateY(-2px); 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.join-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}


@media (max-width: 1024px) {
  .membership-card {
    width: calc(33% - 20px);
  }
}

@media (max-width: 768px) {
  .membership-info-reg {
    padding: 20px;
  }

  .membership-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .membership-card {
    width: 100%;
  }

  .membership-subtitle-reg,
  .membership-subtitles {
    font-size: 1.75em;
  }
}
