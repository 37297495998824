.documents-container {
  max-width: 1220px;
  margin: auto;
  padding: 20px;
  background-color: #f2f2f2;
  text-align: center;
}

.documents-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.documents-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.document-item {
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;

  transition: transform 0.3s ease;
}

.document-item:hover {
  transform: translateY(-5px);
}

.document-icon {
  width: 50px;
  margin-bottom: 0.5rem;
}

.document-details {
  margin-top: 0.5rem;
}

.document-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.document-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .documents-grid {
    grid-template-columns: 1fr 1fr;
  }
}
