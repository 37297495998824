.affiliates-container {
  text-align: center;
  background-color: #f5f5f5;
  padding: 50px 0;
}

.affiliates-header h2 {
  color: #333;
  margin-bottom: 40px;
}

.affiliates-header p {
  color: #666;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.affiliates-logos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 30px;
  justify-items: center;
  align-items: center;
}

.affiliates-logos img {
  max-height: 150px;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.affiliates-logos img:hover {
  opacity: 1;
}

@media (max-width: 1500px) {
  .affiliates-logos img {
    max-height: 120px;
  }
}

@media (max-width: 1100px) {
  .affiliates-logos img {
    max-height: 100px;
  }
}

@media (max-width: 1000px) {
  .affiliates-logos img {
    max-height: 80px;
  }
}

@media (max-width: 800px) {
  .affiliates-header h2 {
    font-size: 1.5em;
  }

  .affiliates-header p {
    font-size: 0.9em;
  }

  .affiliates-logos {
    gap: 20px;
  }

  .affiliates-logos img {
    max-height: 70px;
  }
}

@media (max-width: 480px) {
  .affiliates-container {
    padding: 30px 0;
  }

  .affiliates-header h2 {
    font-size: 1.8em;
    padding: 0 20px;
  }

  .affiliates-header p {
    font-size: 0.8em;
    padding: 0 20px;
  }

  .affiliates-logos {
    grid-template-columns: 1fr; /* One column */
    gap: 20px;
  }

  .affiliates-logos img {
    max-height: 80px;
    margin-bottom: 10px;
  }
}
