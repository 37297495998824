.partners-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
}

.partners-title {
  font-size: 2em;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #eee;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.partner-card {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.partner-card:hover {
  transform: translateY(-5px);
}

.partner-image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Maintain aspect ratio */
  overflow: hidden;
}

.partner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image is fully visible */
  background-color: rgb(228, 224, 224); /* Add background color to make image edges visible */
}

.partner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}

.partner-card:hover .partner-overlay {
  opacity: 1;
}

.partner-description {
  font-size: 1em;
}

@media (max-width: 768px) {
  .partners-title {
    margin-top: 50px;
  }
}

@media (max-width: 480px) {
  .partners-container {
    padding: 10px;
  }

  .partners-title {
    font-size: 1.5em;
    margin-top: 50px;
  }

  .partner-overlay {
    top: auto; /* Reset top property */
    bottom: 0;
    height: 30%; /* Cover bottom 20% of the image */
    opacity: 1; /* Always show overlay on mobile devices */
  }

  .partner-card:hover .partner-overlay {
    opacity: 1; /* Ensure hover state does not affect overlay visibility on mobile */
  }
}
