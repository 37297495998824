.fiva-description-container6 {
  max-width: 1220px;
  margin: 40px auto;
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.fiva-description-title6 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.fiva-description-container6 p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 16px;
}



.download-button {
  display: block;
  background-color: #dc3545;
  color: white;
  padding: 15px 40px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.download-button:hover {
  background-color: #c82333;
}
