/* Container for the entire survey list */
.surveys-container {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Title of the survey list page */
  .surveys-title {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Grid layout for survey cards */
  .surveys-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Individual survey card styling */
  .survey-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 260px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .survey-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Link inside survey card */
  .survey-link {
    text-decoration: none;
    color: #3a4839;
    font-weight: bold;
    font-size: 1.2em;
    display: block;
    padding: 10px 0;
    transition: color 0.2s ease;
  }
  
  .survey-link:hover {
    color: #3a483996;
    text-decoration: underline;
  }
  