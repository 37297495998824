.animated-boxes-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 20px;
}

.cardboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: calc(25% - 20px);
  height: 300px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px);
  border-radius: 12px;
  overflow: hidden;
}

.cardboxes.visible {
  animation: fadeInUpwards 1s ease-out forwards;
}

@keyframes fadeInUpwards {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardboxes:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.cardboxes .overlay {
  background: rgba(58, 72, 57, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: background-color 0.5s ease;
}

.cardboxes .text-content {
  color: #ffffff;
  text-align: center;
  padding: 15px;
  transition: color 0.3s ease;
}

.learn-more-btn {
  padding: 12px 25px;
  background-color: #d9534f;
  border: none;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.cardboxes h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.cardboxes p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.learn-more-btn:hover {
  background-color: #bb443c;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.cardboxes:hover .overlay {
  background: rgba(58, 72, 57, 0.95);
}

.cardboxes:hover h3 {
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .cardboxes {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .cardboxes {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .animated-boxes-container,
  .animated-news-section {
    padding: 20px;
  }
  .cardboxes h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease;
  }
}
