.news-page-title-container {
  text-align: center;
  padding: 2rem 0;
}

.news-page-title {
  display: inline-block;
  font-size: 2.5rem;
  position: relative;
  color: #333;
}

.news-page-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background-color: red;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

.news-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}
.article {
  flex: 0 0 calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.article-image {
  width: 100%;
  height: 223px;
  object-fit: cover;
}

.article:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.article-details {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-title {
  font-size: 1rem;
  color: #333;
}

.article-description {
  color: #666;
  margin: 10px 0;
  height: 60px;
  overflow: hidden;
}

.article-read-more {
  align-self: center;
  background-color: transparent;
  border: 2px solid #333;
  border-radius: 20px;
  padding: 8px 16px;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.article-read-more:hover {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

@media (max-width: 1200px) {
  .article {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .article {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .news-page-title {
    font-size: 2rem;
    margin-top: 60px;
  }
}

@media (max-width: 960px) {
  .news-page-title {
    font-size: 2rem;
    margin-top: 60px;
  }
}

@media (max-width: 480px) {
  .news-page {
    padding: 10px;
  }
}
