.news-page-title-wrapper {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.article-title {
  color: #333;
  display: block;
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0 auto 30px;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.info-text {
  font-size: 1em;
  color: #333;
  text-align: center;
  margin: 10px auto;
}

.required-asterisk {
  color: red;
  font-weight: bold;
  margin-right: 5px;
}

.survey-question {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}


.survey-title {
  color: #333;
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
}

.main-article-image-Survey {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .main-article-image-Survey {
    height: 200px;
  }
  .article-title {
    font-size: 1.5rem;
  }
  .survey-title {
    font-size: 1.5rem;
}
}
