@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap");

.services-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin: 0 auto;
}

.title {
  font-family: "Rajdhani", sans-serif;
  font-size: 30px;
  color: hsl(0, 0%, 0%);
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 768px) {
  .title {
    font-size: 50px;
  }
}
