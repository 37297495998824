.fiva-description-container3 {
  max-width: 1220px;
  margin: 40px auto;
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.fiva-description-title3 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 50px;
}

.fiva-description-container3 p {
  text-align: justify;
  margin-bottom: 20px;
}

.download-button {
  display: inline-block;
  background-color: #d72631;
  color: white;
  padding: 15px 40px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.download-button:hover,
.download-button:focus {
  background-color: #d72631;
}

@media (max-width: 768px) {
  .download-button {
    padding: 12px 30px;
    font-size: 14px;
  }

  .fiva-description-container3 {
    padding: 20px;
  }

  .fiva-description-title3 {
    font-size: 28px;
  }
}
