.card-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 1rem;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-imgs {
  flex: 1;
  padding-right: 1rem;
}

.img-display {
  position: relative;
  width: 100%;
  height: 300px; /* Fixed height for the main image */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-showcase {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensure the image scales correctly */
}

.img-select {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-top: 10px;
}

.img-item {
  width: 80px; /* Fixed width for thumbnail images */
  height: 80px; /* Fixed height for thumbnail images */
  overflow: hidden; /* Hide overflow for consistency */
}

.img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image scales correctly */
  cursor: pointer;
  border: 2px solid transparent;
}

.img-item img:hover,
.img-item img:focus,
.img-item img:active {
  border-color: #007bff;
}

.img-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  cursor: pointer;
  padding: 10px;
  border: none;
  font-size: 2rem; /* Increase size of arrows */
  z-index: 1; /* Ensure arrows are on top of images */
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.fullscreen-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  padding: 5px;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
}

.product-content {
  flex: 1;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.product-detail {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.product-detail p {
  margin: 0.5rem 0;
}

.product-contact {
  font-size: 1.2rem;
  color: #3a4839;
  font-weight: bold;
  margin-top: 1rem;
}

.other-vehicles-divider {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.vc_sep_holder {
  flex: 1;
}

.vc_sep_line {
  display: block;
  height: 1px;
  background-color: #ccc;
  width: 100%;
}

.other-vehicles-divider h4 {
  margin: 0 10px;
}

.other-vehicles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.vehicle-card {
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;
  color: #333;
}

.vehicle-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.vehicle-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.vehicle-details {
  padding: 10px;
}

.vehicle-details h2 {
  font-size: 1em;
  margin: 0.5em;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.read-more-link {
  display: block;
  text-align: center;
  padding: 10px 0;
  background: #b34444;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 4px solid #b3444446;
}

.read-more-link:hover {
  background: #d12d2d;
}

@media (max-width: 767px) {
  .fullscreen-toggle {
    display: none;
  }
  .card {
    flex-direction: column;
    margin-top: 50px;
  }
  .product-imgs,
  .product-content {
    padding-right: 0;
    padding-left: 0;
    flex: none;
  }
}
