.event-filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
}
.header-and-selected-filters {
  text-align: left;
}
.left-section,
.right-section {
  flex: 1;
}

.main-title {
  font-weight: bold;
}

.selected-filters {
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;
}

.filter-heading {
  font-size: 15px;
  font-weight: normal;
}

.filters {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select {
  padding: 5px 30px 5px 10px;
  border: 1px solid #000;
  border-radius: 0;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #000;
  height: 40px;
  cursor: pointer;
  position: relative;
}

.filters select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.filters select::-ms-expand {
  display: none;
}

@media (max-width: 1024px) {
  .event-filter-wrapper {
    flex-direction: column;
  }

  .header-and-selected-filters,
  .filter-section {
    width: 100%;
  }

  .filters,
  .filter-heading {
    text-align: center;
    margin-top: 10px;
  }

  .filters {
    justify-content: center;
    flex-direction: column;
  }

  .filters select {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .event-filter-wrapper {
    padding: 10px;
    margin-top: 50px;
  }
  .main-title {
    font-size: 40px;
    font-weight: lighter;
  }
  .filter-section {
    flex-direction: column;
    align-items: start;
  }

  .filter-heading {
    text-align: start;
    margin-bottom: 10px;
  }

  .filters {
    width: 100%;
  }

  .filters select {
    width: 100%;
  }
  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .main-title,
  .selected-filters {
    text-align: start;
  }

  .filters select {
    width: 100%;
  }
}
