.hero-container {
  position: relative;
  width: 100%;
  height: 750px; /* Fixed height */
  overflow: hidden;
}

.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1); /* Slightly zoomed in */
  z-index: 1;
}

.hero-container .top-right-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: auto;
  z-index: 4;
}

.arrow-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  cursor: pointer; /* Make the arrow clickable */
}

.arrow {
  width: 30px;
  height: 30px;
  border-bottom: 8px solid white;
  border-right: 8px solid white;
  transform: rotate(45deg);
  animation: moveUpDown 1.5s infinite;
  margin-bottom: 20px;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateX(-50%) translateY(0) rotate(45deg);
  }
  50% {
    transform: translateX(-50%) translateY(10px) rotate(45deg);
  }
}

@media (max-width: 1300px) {
  .hero-container .top-right-image {
    width: 70px;
  }

  .arrow {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
}

@media (max-width: 768px) {
  .hero-container {
    height: 50vh; /* Adjust for small screens */
  }

  .hero-container .top-right-image {
    width: 50px;
  }

  .arrow {
    width: 20px;
    height: 20px;
    border-width: 4px;
  }
}

@media (max-width: 480px) {
  .hero-container {
    height: 37vh; /* Adjust for small screens */
    top: 60px;
  }

  .hero-container .top-right-image {
    width: 40px;
  }

  .arrow {
    display: none;
  }
}
