/* Container for the gallery page */
.gallery-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Header styles */
.gallery-page h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2.5em;
    color: #444;
}

.gallery-page p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #666;
}

/* Grid layout for gallery images */
.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    padding: 20px;
}

.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    object-fit: cover;
}

.gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal styles */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.enlarged-image {
    max-height: 80vh;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: transparent;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.modal-prev,
.modal-next {
    border: none;
    background: transparent;
    color: white;
    font-size: 48px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.modal-prev {
    left: 20px;
}

.modal-next {
    right: 20px;
}

@media (max-width: 1300px) {
    .gallery-page h1 {
        font-size: 2em;
        margin-top: 70px;
    }
}
@media (max-width: 1200px) {
    .gallery-page h1 {
        font-size: 2em;
        margin-top: 70px;
    }
}

@media (max-width: 768px) {
    .gallery-page h1 {
        font-size: 1.5em;
    }
    
    .image-gallery {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    
    .enlarged-image {
        max-height: 70vh;
        max-width: 80%;
    }
    
    .close-modal {
        font-size: 20px;
        top: 15px;
        right: 15px;
    }
    
    .modal-prev,
    .modal-next {
        font-size: 36px;
    }
}

@media (max-width: 480px) {
    .gallery-page h1 {
        font-size: 1.2em;
    }
    
    .gallery-image {
        border-radius: 5px;
    }
    
    .enlarged-image {
        max-height: 60vh;
        max-width: 70%;
    }
    
    .close-modal {
        font-size: 18px;
        top: 10px;
        right: 10px;
    }
    
    .modal-prev,
    .modal-next {
        font-size: 24px;
    }
}
