.membership-infoCalendar {
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.membership-titleCalendar {
  font-size: 3em;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.membership-infoCalendar p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.membership-subtitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 20px;
}

.membership-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.membership-list li {
  margin-bottom: 10px;
}

.membership-note {
  font-style: italic;
  font-size: 14px;
  margin-top: 20px;
}

.membership-offer {
  margin: 20px 0;
  font-size: 16px;
}

.membership-detail,
.membership-instructions {
  font-size: 16px;
}

.membership-steps {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.membership-steps li {
  margin: 10px 0;
}

.membership-titleCalendar::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background-color: #b34444;
  margin: 10px auto;
}

@media screen and (max-width: 768px) {
  .membership-titleCalendar {
    font-size: 2em;
  }

  .membership-infoCalendar p {
    font-size: 14px;
  }

  .membership-subtitle {
    font-size: 1.3em;
  }
}
