.parallax-container {
  position: relative;
  height: 50vh;
  overflow: hidden;
}

.parallax-image {
  height: 200vh;
  position: absolute;
  top: -50vh;
  left: 0;
  right: 0;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.overlayParallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a48394f;
  z-index: 1;
}

.content-containerParallax {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  z-index: 2;
}

.titleParallax {
  color: white;
  font-size: 3em;
  margin-bottom: 0.5em;
}

.join-button-Parallax {
  padding: 12px 30px;
  font-size: 1em;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
}

.join-button-Parallax:hover {
  color: black;
  background-color: white;
}

/* Adjustments for iPads and other touch devices with similar resolutions */
@media (max-width: 1024px) and (pointer: coarse) {
 

  .titleParallax {
    font-size: 2.5em; /* Adjust the title size for better readability */
  }
}

/* Refine styles for smaller devices like iPhones */
@media (max-width: 768px) {
  .parallax-image {
    background-attachment: scroll;
    position: relative;
    height: 50vh;
    top: 0;
  }

  .titleParallax {
    font-size: 2em;
  }

  .join-button-Parallax {
    padding: 10px 20px;
    font-size: 0.8em;
  }
}

/* Further refinements for very small devices */
@media (max-width: 480px) {
  .titleParallax {
    font-size: 1.5em;
  }

  .join-button-Parallax {
    padding: 10px 20px;
    font-size: 0.8em;
  }
}
