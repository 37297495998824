@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Noto+Serif+Vithkuqi:wght@500&family=Quicksand:wght@300&family=Satisfy&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap");

:root {
  --primary: #00312b;
  --yellow: #f9d806;
  --black: #ffffff;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-4 {
  color: var(--dark);
  font-weight: 700px;
}

a {
  color: var(--dark);
  text-decoration: none;
}

* {
  font-family: Sora, "sans-serif";
}

body {
  background-color: #f4f4f4;
  color: #000000; /* Ensure text color is set for light mode */
}

/* Ensure light mode is enforced regardless of user settings */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #f4f4f4 !important;
    color: #000000 !important;
  }
}
