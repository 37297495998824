.wrapper {
  max-width: 1220px;
  margin: auto;
  padding: 20px;
}

.main-article-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
}

.content-block {
  padding: 15px;
}

footer,
.parent-component {
  width: 100%;
}

.video-block {
  text-align: left;
  width: 80%;
  display: block;
}

.video-content {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: normal;
  color: #000000;
  margin-bottom: 15px;
  text-align: left;
}

.random-page-image-block {
  text-align: left;
  width: 80%;
  display: block;
  position: relative;
  overflow: hidden;
}

.aspect-ratio-box {
  width: 100%;
  padding-top: 50%;
  position: relative;
}

.random-page-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 1.2rem;
    padding-left: 10px;
  }
}

.article-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  margin: 0 auto 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  max-width: 100%;
  display: block;
}

.article-title::after {
  content: "";
  display: block;
  width: 200px;
  height: 3px;
  background-color: #b34444;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .article-title {
    font-size: 2em;
  }

  .main-article-image {
    height: 200px;
  }

  .wrapper {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .article-title {
    font-size: 1.5rem;
  }
}

.content-block {
  text-align: left;
}

h1 {
  margin-bottom: 1em;
  color: #333;
  font-size: 2rem;
}

.content-block p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1em;
}

.content-block a {
  color: #007bff;
  text-decoration: none;
}

.content-block a:hover,
.content-block a:focus {
  text-decoration: underline;
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .content-block p {
    font-size: 0.9rem;
  }
}

.registration-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  max-width: 800px;
}

.registration-title {
  color: #333;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.form-requirement {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.extra-companions label {
  display: flex
  ;
      align-items: center;
      margin: 10px 0;
      font-size: 0.95rem;
      margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: start;
}
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white; /* Ensure it matches input backgrounds */
  appearance: none; /* Remove default browser styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer; /* Makes it feel more like an input */
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="number"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 5px; /* Added margin to separate label from input */
}

.pricing-options {
  margin-top: 20px;
}

.pricing-options h3 {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.pricing-options label {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 0.95rem;
}

.pricing-options input[type="radio"] {
  margin-right: 10px;
}

.additional-info {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  font-size: 0.9rem;
  color: #666;
}

.submit-button {
  background-color: #3a4839;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #45a049;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 20px;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  object-fit: cover;
}

.gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.enlarged-image {
  max-height: 80vh;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.modal-prev,
.modal-next {
  border: none;
  background: transparent;
  color: white;
  font-size: 48px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-prev {
  left: 20px;
}

.modal-next {
  right: 20px;
}

@media (max-width: 768px) {
  .article-title {
    font-size: 2em;
  }

  .main-article-image {
    height: 200px;
  }

  .wrapper {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .article-title {
    font-size: 1.5rem;
  }
}


.content-block {
  text-align: left;
}

h1 {
  margin-bottom: 1em;
  color: #333;
  font-size: 2rem;
}

.content-block p {
  font-size: 1rem;
  line-height: 1.6;
  color: #000000;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1em;
}

.content-block a {
  color: #007bff;
  text-decoration: none;
}

.content-block a:hover,
.content-block a:focus {
  text-decoration: underline;
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .content-block p {
    font-size: 0.9rem;
  }
}

.registration-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  max-width: 800px;
}

.registration-title {
  color: #333;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.form-requirement {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: start;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pricing-options {
  margin-top: 20px;
}

.pricing-options h3 {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.pricing-options label {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 0.95rem;
  margin-bottom: 20px;

}

.pricing-options input[type="radio"] {
  margin-right: 10px;
}

.additional-info {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  font-size: 0.9rem;
  color: #666;
}

.submit-button {
  background-color: #3a4839;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #45a049;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 20px;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  object-fit: cover;
}

.gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.enlarged-image {
  max-height: 80vh;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.modal-prev,
.modal-next {
  border: none;
  background: transparent;
  color: white;
  font-size: 48px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-prev {
  left: 20px;
}

.modal-next {
  right: 20px;
}

@media (max-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.program-block {
  margin: 20px 0;
}

.program-block h2 {
  color: #495057;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.program-block p {
  margin-bottom: 10px;
  color: #343a40;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.program-block p:not(:last-child) {
  border-bottom: 1px dotted #bbb;
  padding-bottom: 10px;
}
.youtube-video-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
}

.youtube-video-block iframe {
  width: 560px;
  height: 315px;
  max-width: 100%;
  border: none;
}

@media (max-width: 960px) {
  .main-article-image {
    margin-top: 60px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wrapper {
    padding: 10px;
  }

  .main-article-image {
    margin-top: 70px;
    height: 200px;
  }

  .article-title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.2rem;
    padding-left: 10px;
  }

  .content-block p {
    font-size: 0.9rem;
  }

  .registration-form {
    padding: 15px;
    margin: 10px auto;
  }

  .registration-title {
    font-size: 1.2rem;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="tel"] {
    padding: 8px;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .article-title {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .content-block p {
    font-size: 0.8rem;
  }

  .registration-form {
    padding: 10px;
  }

  .registration-title {
    font-size: 1rem;
  }

  .submit-button {
    font-size: 0.8rem;
    padding: 7px 10px;
  }
}
