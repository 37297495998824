.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.5rem;
  color: #000;
  text-align: center;
  max-width: 100%;
  margin: 3rem auto;
  padding: 3rem;
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  overflow: hidden;
}

.banner h2 {
  font-size: 2.2rem;
  color: #525252;
  white-space: normal;
  word-wrap: break-word;
  margin: 0 0.5rem;
  flex: 0 0 auto;
}

.slideFromLeft,
.slideFromRight {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.slideFromLeft {
  transform: translateX(-100%);
}

.slideFromRight {
  transform: translateX(100%);
}

.visible .slideFromLeft,
.visible .slideFromRight {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .banner {
    padding: 2rem;
    font-size: 1.2rem;
  }

  .banner h2 {
    font-size: 1.2rem;
    margin: 0 0.5rem;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 1rem;
    font-size: 1rem;
    margin-top: 80px;
    margin-bottom: 10px;
  }

  .banner h2 {
    font-size: 4vw;
    margin: 0 0.25rem;
  }
}

@media (max-width: 320px) {
  .banner h2 {
    font-size: 5vw;
  }
}
