.sticky-header {
  background-color: #3a4839;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}


.sticky-search-bar {
  display: flex;
  width: 100%;
}

.sticky-search-bar input {
  flex-grow: 1;
  margin-right: 10px;
}

.sticky-icons {
  display: flex;
  align-items: center;
}

.language-toggle {
  display: inline-flex;
  align-items: center;
  background-color: #3a4839;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
  font-weight: bold;
  margin: 0 20px;
  padding: 5px 20px;
}

.language-toggle span {
  color: #fff;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
}

.language-toggle span.active {
  background-color: #d9534f;
  color: #fff;
  border-radius: 15px;
}

.language-toggle span:hover:not(.active) {
  background-color: #324a3d;
  border-radius: 15px;
  color: #fff;
}

@media (max-width: 768px) {
  .language-toggle {
    margin: 0 10px;
  }
}

.sticky-icons img {
  padding: 5px;
  cursor: pointer;
}

.language-toggle span:hover,
.sticky-join-btn:hover,
.sticky-icons img:hover {
  opacity: 0.7;
}

.sticky-icons img {
  cursor: pointer;
  margin-right: 10px;
}
.sticky-join-btn:active {
  background-color: #af271f;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.sticky-join-btn {
  padding: 10px 25px;
  background-color: #d9534f;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}
.sticky-join-btn:hover {
  background-color: #c12e2a;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.sticky-header .sticky-logo {
  width: 200px;
}

.sticky-header .sticky-icons img {
  width: 50px;
}
.menu .head {
  display: none;
}
.menu ul {
  list-style: none;
}
.menu > ul > li {
  display: inline-block;
}
.menu > ul > li:not(:last-child) {
  margin-right: 40px;
}
.menu .dropdown {
  position: relative;
}
.menu a {
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 13px;
  color: hsl(0, 0%, 100%);
  line-height: 1.5;
  display: block;
}
.menu > ul > li > a {
  padding: 16px 0;
}
.menu > ul > .dropdown > a {
  padding-right: 15px;
}
.menu i {
  font-size: 10px;
  pointer-events: none;
  user-select: none;
  position: absolute;
  color: hsl(0, 0%, 100%);
  top: calc(50% - 5px);
}
.menu > ul > li > i {
  right: 0;
}
.menu .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  padding: 15px 0;
  background-color: #5d5d5d;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);

  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.5);
  z-index: 1;
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  opacity: 0;
}
.menu .sub-menu-right {
  left: 100%;
  top: 0;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);
}
.menu .sub-menu-left {
  top: 0;
  left: auto;
  right: 100%;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);
}
.menu li:hover > .sub-menu {
  background-color: #3a4839;
  opacity: 1;
  transform: none;
  visibility: visible;
  transition: all 0.5s ease;
}
.menu .sub-menu a {
  padding: 6px 24px;
}
.menu .sub-menu .dropdown > a {
  padding-right: 34px;
}
.menu .sub-menu span {
  background-image: linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 100%));
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size 0.5s ease;
}
.menu .sub-menu li:hover > a > span {
  background-size: 100% 1px;
}
.menu .sub-menu i {
  transform: rotate(-90deg);
  right: 24px;
}
.sticky-icons i,
.sticky-icons img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 15px;
  cursor: pointer;
}

.sticky-icons img[alt="Search"] {
  width: 24px;
  height: 24px;
}

.sticky-icons {
  margin-right: 20px;
}

.sticky-join-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 14px;
  margin-left: 15px;
}

.sticky-header {
  background-color: #3a4839;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.sticky-search-bar {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.sticky-search-bar .search-input {
  flex-grow: 1;
  padding: 10px;
  background-color: #3a4839;
  border: none;
  color: white;
}

.sticky-search-bar .search-close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
}

.sticky-icons img {
  cursor: pointer;
  margin-right: 10px;
  width: 24px;
  height: auto;
}

.sticky-header .logo1 {
  display: block;
}
.sticky-header .sticky-logo {
  width: 270px;
}
.sticky-header .logo2 {
  display: none;
}
.fiva-logo-container {
  position: fixed;
  top: 60px; /* Height of the sticky header */
  right: 0;
  z-index: 99; /* One level below the main sticky header */
  background-color: transparent; /* Match the header's background or use a suitable color */
  padding: 5px 20px; /* Adjust padding as necessary */
}
.fiva-logo {
  height: auto; /* Adjust height as necessary */
  width: 100px; /* Keep width proportional to the height */
}
@media (max-width: 1950px) {
  .menu a {
    font-size: 13px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 30px;
  }

  .sticky-join-btn {
    font-size: 12px;
  }
}
@media (max-width: 1850px) {
  .menu a {
    font-size: 11px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 30px;
  }

  .sticky-join-btn {
    font-size: 12px;
  }
}

@media (max-width: 1750px) {
  .menu a {
    font-size: 10px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 25px;
  }

  .sticky-join-btn {
    font-size: 10px;
  }
  .language-toggle span {
    font-size: 12px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1650px) {
  .menu a {
    font-size: 10px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 13px;
  }

  .sticky-join-btn {
    font-size: 11px;
  }
  .language-toggle span {
    font-size: 10px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1550px) {
  .menu a {
    font-size: 9px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 13px;
  }

  .sticky-join-btn {
    font-size: 9px;
  }
  .language-toggle span {
    font-size: 13px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1450px) {
  .menu a {
    font-size: 9px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 7px;
  }

  .sticky-join-btn {
    font-size: 9px;
  }
  .language-toggle span {
    font-size: 12px;
    transition: color 0.3s ease;
  }
  .sticky-header .sticky-logo {
    width: 200px;
  }

}

@media (max-width: 1350px) {
  .menu a {
    font-size: 9px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 7px;
  }

  .sticky-join-btn {
    font-size: 8px;
  }
  .language-toggle span {
    font-size: 10px;
    transition: color 0.3s ease;
  }
  .sticky-header .sticky-logo {
    width: 180px;
  }

}
@media (max-width: 1250px) {
  .menu a {
    font-size: 8px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 8px;
  }

  .sticky-header .sticky-logo {
    width: 40px;
  }
  .sticky-join-btn {
    font-size: 8px;
  }
  .language-toggle span {
    font-size: 9px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1100px) {
  .menu a {
    font-size: 8px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 8px;
  }
 
  .sticky-header .sticky-logo {
    width: 50px;
  }
  .sticky-join-btn {
    display: none;
  }
  .language-toggle span {
    font-size: 12px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 1000px) {
  .menu a {
    font-size: 8.5px;
  }
  .sticky-header .menu > ul > li:not(:last-child) {
    margin-right: 7px;
  }

  .sticky-header .sticky-logo {
    width: 50px;
  }
  .sticky-join-btn {
    display: none;
  }
  .language-toggle span {
    font-size: 11px;
    transition: color 0.3s ease;
  }
}

@media (max-width: 960px) {
  .sticky-header {
    display: none;
  }
  .fiva-logo-container {
    display: none; /* Optional: hide the logo on smaller screens */
  }
}
