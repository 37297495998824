/* Common styles */
.events-list-container {
  max-width: 1220px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0 20px;
}

.event-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.event-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.event-image {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image {
  transform: scale(1.1);
}

.event-date-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35%;
  height: 100%;
  background: rgba(58, 72, 57, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: #ffffff;
  font-size: 1em;
  border-top-right-radius: 8px;
}

.event-title {
  background: #f7f7f7;
  color: #333;
  padding: 15px;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  overflow: hidden;
  margin: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.event-article-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #3a4839c2;
  color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;
  font-size: 1.5em;
  line-height: 1;
  transition: background-color 0.3s ease;
}

.event-article-button:hover {
  background-color: #3a4839;
}

/* Styles for single-day events */
.single-day .event-date-day {
  font-size: 3em;
}

.single-day .event-date-month {
  font-size: 1.2em;
}

/* Styles for multiple-day events */
.multiple-day .event-date-day {
  font-size: 2.5em;
}

.multiple-day .event-date-month {
  font-size: 1em;
  margin-top: 5px;
}

/* Media queries */
@media (max-width: 1024px) {
  .events-list-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .event-card {
    border-radius: 6px;
  }

  .event-date-container {
    border-top-right-radius: 6px;
  }

  .event-title {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

@media (max-width: 768px) {
  .events-list-container {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .event-card {
    border-radius: 4px;
  }

  .event-date-container {
    border-top-right-radius: 4px;
  }

  .event-title {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .event-date-day {
    font-size: 2.5em;
  }

  .event-date-month {
    font-size: 1em;
  }
}
