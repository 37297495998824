.image-container1 {
  width: 100vw;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.image-container1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 70%;
}
