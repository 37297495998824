.top-banner {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.123);

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3a4839;
  padding: 10px 0;
}

.top-banner img:first-child {
  margin-left: 35px;
  max-height: 70px;
}
.left-section {
  display: flex;
  align-items: center;
}
.top-banner img:last-child {
  max-height: 70px;
}
.search-btn.icon-btn {
  margin-left: auto;
}
.top-banner .right-section {
  display: flex;
  align-items: center;
}

.search-btn img {
  width: 20px;
  height: auto;
}
.search-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.search-input::placeholder {
  color: white;
  opacity: 1;
}
.search-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}
.search-input {
  border: none;
}
.search-close-line {
  background-color: #ccc;
  width: 1px;
  height: 24px;
  margin: 0 10px;
}

.vertical-line {
  height: 30px;
  width: 1px;
  background-color: #ffffff;
  margin: 0 20px;
}
.search-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search-icon {
  display: block;
}
.search-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
}
.search-bar img.search-icon {
  display: none;
}

.search-bar.active img.search-icon {
  display: block;
}

.search-section .search-btn.icon-btn {
  display: block;
}

.search-section .search-btn.icon-btn.active {
  display: none;
}

.search-bar {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.search-bar img {
  width: 20px;
  height: auto;
}
.search-bar button {
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
}
.search-bar input {
  width: 100%;
  padding: 10px;
  background-color: #3a4839;
  border: none;
  color: white;
}

.new-external-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 37px;
  padding: 0 30px;
}

.new-external-links a,
.new-external-links span {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.75em;
  padding: 0 5px;
}
.new-external-links a i.fa,
#login-btn .btn .fa {
  font-size: 14px;
}
.social-links {
  display: flex;
  gap: 5px;
}

.social-links .follow-us {
  margin-right: 10px;
}

.fa {
  font-size: 14px;
  color: #ffffff;
}
@media (max-width: 991px) {
  .top-banner img:first-child {
    max-height: 55px;
  }
  .top-banner img:last-child {
    max-height: 55px;
  }
}
