.membership-infoMatriculation {
  max-width: 1220px;
  margin: 0 auto;
  padding: 30px;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.membership-titleMatriculation {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.membership-infoMatriculation p {
  text-align: justify;
  margin-bottom: 20px;
  line-height: 1.6;
}

.membership-subtitleMatriculation {
  font-size: 1.3em;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.membership-listMatriculation {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.membership-listMatriculation li {
  text-align: justify;
  margin-bottom: 10px;
}

.membership-offerMatriculation {
  margin: 20px 0;
  font-size: 16px;
}

.membership-detailMatriculation,
.membership-instructions {
  font-size: 16px;
}

.membership-stepsMatriculation {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.membership-stepsMatriculation li {
  margin: 10px 0;
}

.price-highlight {
  background-color: #3a4839; /* Matching your button color for consistency */
  color: white;
  padding: 3px 10px;
  font-weight: bold;
  border-radius: 15px; /* Slightly rounded corners for a tag-like appearance */
  display: inline-block; /* Allows us to set padding and other block properties */
  margin-left: 5px; /* Adds a bit of space between the text and the tag */
}

.fiva-description-container {
  font-family: "Arial", sans-serif;
  max-width: 1220px;
  margin: 20px auto;
  padding: 20px;
  color: #333;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.fiva-description-title,
.fiva-description-subtitle {
  color: #333;
  text-align: center;
  margin: 20px 0;
}

.fiva-description-title {
  font-size: 2em;
  font-weight: bold;
}

.fiva-description-subtitle {
  font-size: 1.5em;
  font-weight: bold;
}

.fiva-description-container p {
  text-align: justify;
  margin-bottom: 15px;
  line-height: 1.5;
}

.prices-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 30px;
}

.price-card {
  background-color: #ffffff;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  flex: 1 1 45%;
}

.price-card-title {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}

.price-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.price-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.download-button {
  background-color: #dc3545;
  color: white;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #c82333;
}

.categories-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.categories-table th,
.categories-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.categories-table th {
  background-color: #f8f9fa;
  color: #333;
}

@media (max-width: 768px) {
  .prices-container {
    flex-direction: column;
    align-items: center;
  }

  .price-card {
    margin-bottom: 20px;
  }

  .price-card-title {
    font-size: 1.1em;
  }

  .price-item {
    font-size: 0.9em;
  }

  .price-icon {
    width: 30px;
    height: 30px;
  }

  .categories-table thead {
    display: none;
  }

  .categories-table,
  .categories-table tbody,
  .categories-table tr,
  .categories-table td {
    display: block;
  }

  .categories-table td {
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0;
  }

  .categories-table td:not(:last-child) {
    border-bottom: 0;
  }

  .download-button {
    padding: 12px 20px;
    font-size: 1em;
  }
}
