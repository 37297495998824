.certifications-info-container {
  font-family: "Arial", sans-serif;
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.certifications-info-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.certifications-info-heading {
  font-size: 24px;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 15px;
}

.certifications-info-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .certifications-info-title {
    font-size: 2em;
  }

  .certifications-info-heading {
    font-size: 1.5em;
  }

  .certifications-info-container {
    padding: 15px;
  }

  .certifications-info-container p {
    font-size: 1em;
  }
}
