.fixed-social {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1000;
  bottom: 100px;
}

.social-btns {
  left: -100px;
  opacity: 0;
  visibility: hidden;
  transition: left 0.5s ease, opacity 0.5s ease;
}

.social-btns a {
  text-decoration: none;
  display: block;
  margin: 10px 0;
  perspective: 200px;
  pointer-events: auto;
}

.social-btns a:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.social-btns .box {
  --box-size: 45px;
  display: block;
  height: var(--box-size);
  width: var(--box-size);
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(calc(var(--box-size) / -2));
  transition: transform 0.5s ease;
}

.social-btns a:hover .box {
  transform: translateZ(calc(var(--box-size) / -2)) rotateX(90deg);
}

.social-btns .face {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media-container {
  position: fixed;
  left: 0;
  z-index: 1000;
}

.social-btns .front {
  background-color: hsl(0, 0%, 97%);
  color: hsl(240, 6%, 16%);
  transform: translateZ(calc(var(--box-size) / 2));
}

.social-btns .bottom {
  background-color: red;
  color: hsl(0, 0%, 97%);
  transform: translateY(50%) rotateX(-90deg);
}

.toggle-visibility-button {
  position: fixed;
  left: 10px;
  top: calc(48% + 00px);
  z-index: 1001;
  cursor: pointer;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  outline: none;
  box-shadow: none;
  border: none;
}

.toggle-visibility-button.opened {
  transform: rotate(180deg);
}

.fixed-social.show {
  bottom: 50px;
}

.toggle-visibility-button i {
  color: #fdfdfd;
  font-size: 10px;
  transition: transform 0.3s ease;
}

.toggle-visibility-button.show i {
  transform: rotate(180deg);
}

.social-btns.show {
  left: 0;
  opacity: 1;
  visibility: visible;
}

@keyframes slideInFromLeft {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .fixed-social {
    display: none;
  }
  .toggle-visibility-button {
    bottom: 10px;
  }
}

@media (max-width: 1500px) {
  .toggle-visibility-button {
    display: none;
  }
  .social-btns {
    display: none;
  }
}
