.club-info-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
}

.club-info-status-header {
  text-align: center;
  margin-bottom: 30px;
}

.club-info-status-header h2 {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 1em;
}

.club-info-status-body {
  width: 100%;
  max-width: 1220px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 30px;
}

.club-info-status-body p {
  font-size: 1rem;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 15px;
}

.club-info-status-body p strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .club-info-status-container {
    padding: 30px 10px;
  }

  .club-info-status-header h2 {
    font-size: 1.75rem;
  }

  .club-info-status-body {
    padding: 20px;
    max-width: 95%;
  }
}

@media (max-width: 480px) {
  .club-info-status-container {
    padding: 20px 10px;
  }

  .club-info-status-header h2 {
    font-size: 1.5rem;
  }

  .club-info-status-body {
    padding: 15px;
    max-width: 100%;
  }

  .club-info-status-body p {
    font-size: 0.9rem;
  }
}
