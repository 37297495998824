.satisfaction-content {
    max-width: 1220px;
    margin: auto;
    padding: 20px;
}

.news-page-title-wrapper{
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.survey-question-container {
    margin-top: 20px;
    padding: 15px;
}
.required-fields-text {
    font-size: 1em;
    color: #333;
    text-align: center;
    margin: 10px auto;
    padding: 0 10px; /* Prevents overflow by adding padding */
    max-width: 100%; /* Ensures it stays within the screen width */
}

.required-asterisk {
    color: red;
    margin: 0 5px;
}


.required-asterisk {
    color: red;
    font-weight: bold;
}

.survey-question {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.survey-options label {
    display: block;
    font-size: 1em;
    color: #555;
    margin: 5px 0;
}

.survey-options input[type="radio"] {
    margin-right: 10px;
}

.additional-feedback {
    margin-top: 20px;
}

.additional-feedback p {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.feedback-textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    resize: vertical;
    font-size: 1em;
}
.main-article-image-Survey {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
    .main-article-image-Survey {
        margin-top: 20px;
        height: 200px;
    }
}