.membership-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.membership-form-container {
  background: #fff;
  width: 100%;
  max-width: 538px;
  margin: auto;
  border-radius: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 85vh;
  overflow-y: auto;
}

.input-label {
  text-align: center;
  display: block;
  width: 100%;
}

.membership-form-header {
  background-color: #3a4839;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
}

.membership-form-title {
  font-size: 1rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.close-membership-form {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
}

.membership-form {
  padding: 20px;
}

.form-group {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.form-control,
.PhoneInputInput,
.PhoneInputCountrySelect {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.phone-input-group {
  display: flex;
  align-items: center;
}

.country-code {
  flex-basis: 40%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-control.phone-number {
  flex-basis: 60%;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.phone-icon {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.help-text {
  color: #666;
  font-size: 10px;
  text-align: center;
  padding: 20px;
  background: #f4f4f4;
  width: 100%;
  box-sizing: border-box;
}

.form-control,
.PhoneInputInput,
.PhoneInputCountrySelect {
  padding: 0.7rem;
  font-weight: 300;
}

.submit-button {
  margin-top: 10px;
  color: white;
  background-color: #3a4839;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3a4839af;
}

.submit-text {
  font-size: 0.7rem;
}

body.no-scroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .membership-form-container {
    max-width: 95%;
    max-height: 85vh;
    overflow-y: auto;
  }

  .help-text {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .close-membership-form {
    top: 10px;
    transform: translateY(0);
    font-size: 1.25rem;
  }

  .membership-form-header {
    padding: 15px;
  }

  .membership-form-title {
    font-size: 1.2rem;
    margin-right: 40px;
  }
}
