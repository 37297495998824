#site-footer {
  background-color: #3a4839;
  background-image: url("https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg");
  background-position: center center;
  background-repeat: repeat-x;
  background-size: cover;
  color: #ffdf97;
  font-size: 13px;
  padding-top: 40px;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  outline: 0;
}

div {
  display: block;
}

.wrapper {
  max-width: 1500px;
  margin: auto;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}

body {
  color: #292829;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  line-height: 1.6;

  margin-top: 0;

  min-width: 200px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.social-btns a {
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;
  perspective: 200px;
}

.social-btns .box {
  --box-size: 50px;
  display: block;
  height: var(--box-size);
  width: var(--box-size);
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(calc(var(--box-size) / -2));
  pointer-events: none;
  transition: transform 0.5s ease;
}

.social-btns a:hover .box {
  transform: translateZ(calc(var(--box-size) / -2)) rotateX(90deg);
}

.social-btns .face {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-btns .front {
  background-color: hsl(0, 0%, 97%);
  color: hsl(240, 6%, 16%);
  transform: translateZ(calc(var(--box-size) / 2));
}

.social-btns .bottom {
  background-color: hsl(332, 75%, 55%);
  color: hsl(0, 0%, 97%);
  transform: translateY(50%) rotateX(-90deg);
}

a img {
  border: medium none;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

#footer-lower-section {
  background-color: #1a1a1a;
  color: #fff;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .wrapper {
    max-width: 100%;
    padding: 0 20px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column a img {
    width: auto;
    height: auto;
    margin: 20px 0;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
}
