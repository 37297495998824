.member-benefits-wrapper {
  max-width: 1220px;
  margin: auto;
  padding: 40px;
}

.member-benefits-title {
  font-size: 2.5em;
  color: #333;
  text-align: center;
  margin-bottom: 1.5em;
}

.member-benefits-container2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px;
  justify-content: center;
}

.benefit-item {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s;
}

.benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

.benefit-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.benefit-content {
  padding: 20px;
  text-align: center;
}

.benefit-title {
  font-size: 1.25em;
  font-weight: bold;
  color: #333;
  margin-top: 0.5em;
}

.benefit-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 1em;
}

.member-benefits-button-container {
  display: none;
}
.member-benefits-button-container {
  text-align: center;
  margin-top: 40px;
  display: block;
}

.join {
  padding: 12px 30px;
  font-size: 1em;
  color: white;
  background-color: #ff0000;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
}

.join:hover {
  background-color: #d9534f;
  color: white;
}

@media (max-width: 768px) {
  .member-benefits-container2 {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .member-benefits-container2 {
    padding: 15px;
  }

  .benefit-title {
    font-size: 1.1em;
  }

  .benefit-description {
    font-size: 0.95em;
  }
  .member-benefits-wrapper {
    padding: 0px;
  }
  .member-benefits-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 0px;
    padding: 20px;
  }
}
