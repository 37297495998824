.additional-info-container {
  max-width: 1220px;
  margin: auto;
  padding: 40px 20px;
  font-family: "Arial", sans-serif;
}

.additional-info-header {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 50px;
  text-align: center;
}

.info-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

.info-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 22px rgba(0, 0, 0, 0.2);
}

.info-card-image {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.info-card-content {
  padding: 20px;
}

.info-card-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: left;
}

.info-card-description {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
  height: 4.5em;
  overflow: hidden;
  position: relative;
}

.info-card-description::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);
}

.info-card-read-more {
  margin-top: auto;
  text-transform: uppercase;
  font-weight: bold;
  color: #ff0000;
  text-align: center;
  text-decoration: none;
  padding: 12px;
  border-radius: 20px;
  transition: background-color 0.2s, color 0.2s;
}

.info-card-read-more:hover {
  background-color: #ff0000;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .info-cards-container {
    grid-template-columns: 1fr;
  }
}
