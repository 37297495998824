.page-in-construction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #3a4839c5;
}
.construction-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 40%;
}
.construction-text {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}
