.membership-infoPreClassic {
  max-width: 1220px;
  margin: 0 auto;
  padding: 30px;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.membership-titlePreClassic {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.membership-infoPreClassic p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.membership-subtitlePreClassic {
  font-size: 1.3em;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.membership-listPreClassic {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.membership-listPreClassic li {
  text-align: justify;
  margin-bottom: 10px;
}

.membership-offerPreClassic {
  margin: 20px 0;
  font-size: 16px;
}

.membership-detailPreClassic,
.membership-instructionsPreClassic {
  font-size: 16px;
}

.membership-stepsPreClassic {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.membership-stepsPreClassic li {
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .membership-infoPreClassic {
    padding: 20px;
  }

  .membership-titlePreClassic {
    font-size: 2em;
  }

  .membership-infoPreClassic p {
    font-size: 14px;
  }

  .membership-subtitlePreClassic {
    font-size: 1.2em;
  }
}
.membership-infoPreClassic a {
  color: #007bff; /* You can change this color to whatever suits your design */
  text-decoration: underline;
  cursor: pointer;
}

.membership-infoPreClassic a:hover {
  color: #0056b3; /* Darker color on hover */
  text-decoration: underline;
}