.banner-container {
  width: 100vw;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 10%;
}
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.281);
  z-index: 1;
}
.banner-text {
  position: absolute;
  text-align: center;
  color: white;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.banner-title {
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  margin: 0;
}

.banner-subtitle {
  font-size: 1.2em;
  margin-top: 10px;
  color: #fff;
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 2em;
  }

  .banner-subtitle {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .banner-title {
    font-size: 1.8em;
    width: 300px;
  }

  .banner-subtitle {
    display: none;
  }
  .banner-text {
    top: 60%;
    left: 50%;
  }
}
