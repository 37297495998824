.team-section {
  max-width: 1220px;
  margin: auto;
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.team-section-title {
  margin-bottom: 50px;
  color: #333;
  font-size: 2.5em;
}

.team-category {
  margin-bottom: 60px;
}

.team-category h3 {
  margin-bottom: 30px;
  color: #222;
  font-size: 1.8em;
}

.team-members-container,
.team-members-container2,
.team-members-container3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.team-member-card {
  flex: 0 1 calc(33.333% - 40px);
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member-image {
  width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.team-member-info {
  text-align: center;
}

.team-member-name {
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 10px;
}

.team-member-role {
  font-size: 1.1em;
  color: #666;
}

.team-member-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .team-member-card {
    flex: 0 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .team-member-card {
    flex: 0 1 calc(50% - 40px);
  }
}

@media (max-width: 576px) {
  .team-member-card {
    flex: 0 1 calc(100% - 20px);
    margin-bottom: 20px;
  }

  .team-section {
    padding: 40px 10px;
  }

  .team-section-title,
  .team-category h3 {
    font-size: 1.5em;
  }

  .team-member-name {
    font-size: 1.2em;
  }

  .team-member-role {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .team-member-card {
    flex: 0 1 calc(100% - 20px);
    margin-bottom: 20px;
  }

  .team-section-title,
  .team-category h3 {
    font-size: 1.3em;
  }

  .team-member-name {
    font-size: 1.1em;
  }

  .team-member-role {
    font-size: 0.9em;
  }
}
