@media (max-width: 1300px) {
  .headerMobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #3a4839;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.685);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  .headerMobile .menu {
    padding: 20px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #3a4839;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    padding-top: 1em;
    box-sizing: border-box;
  }

  .open-menu-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
  }

  .open-menu-btn .line {
    display: block;
    width: 100%;
    height: 2px;
    background: white;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: center;
  }

  .open-menu-btn.active .line-1 {
    transform: translateY(-50%) rotate(45deg);
  }

  .open-menu-btn.active .line-2 {
    opacity: 0;
    pointer-events: none;
  }

  .open-menu-btn.active .line-3 {
    transform: translateY(-50%) rotate(-45deg);
  }

  .headerMobile .menu > ul {
    padding-left: 0;
    width: 100%;
  }

  .headerMobile .menu.open {
    transform: translateX(0);
  }

  .headerMobile .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .headerMobile .menu li {
    padding: 0.75em;
    border-bottom: 1px solid #fff;
  }

  .headerMobile .menu .head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  .headerMobile .menu .close-menu-btn {
    height: 35px;
    width: 35px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .headerMobile .menu .close-menu-btn::before,
  .headerMobile .menu .close-menu-btn::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: hsl(0, 0%, 100%);
  }

  .headerMobile .menu .close-menu-btn::before {
    transform: rotate(45deg);
  }

  .headerMobile .menu .close-menu-btn::after {
    transform: rotate(-45deg);
  }

  .headerMobile .menu > ul > li {
    display: block;
  }

  .headerMobile .menu > ul > li:not(:last-child) {
    margin-right: 0;
  }

  .headerMobile .menu li {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  }

  .headerMobile .menu li a {
    color: #fff;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .headerMobile-right > * {
    display: none;
  }

  .headerMobile .menu li:first-child {
    border-top: 1px solid hsla(0, 0%, 100%, 0.25);
  }

  .headerMobile .menu > ul > li > a {
    padding: 12px 0;
  }

  .headerMobile .menu > ul > .dropdown > a {
    padding-right: 34px;
  }

  .headerMobile .menu i {
    height: 34px;
    width: 34px;
    border: 1px solid hsla(0, 0%, 100%, 0.25);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;
    top: 7px;
  }

  .headerMobile .menu .dropdown.active > i {
    background-color: hsla(0, 0%, 100%, 0.25);
    transform: rotate(180deg);
  }

  .headerMobile .top-banner,
  .headerMobile-right {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerMobile .menu .sub-menu {
    position: static;
    opacity: 1;
    transform: none;
    visibility: visible;
    padding: 0;
    transition: none;
    box-shadow: none;
    width: 100%;
    display: none;
  }

  .headerMobile .menu .dropdown.active > .sub-menu {
    display: block;
  }

  .headerMobile .menu .sub-menu li:last-child {
    border: none;
  }

  .headerMobile .menu .sub-menu a {
    padding: 12px 0 12px 15px;
  }

  .headerMobile .menu .sub-menu .sub-menu a {
    padding-left: 30px;
  }

  .headerMobile .menu .sub-menu .sub-menu .sub-menu a {
    padding-left: 45px;
  }

  .headerMobile .menu .sub-menu span {
    background-image: none;
  }

  .headerMobile .menu .sub-menu i {
    transform: none;
    right: 0;
  }

  .headerMobile-right .open-menu-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 44px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
  }

  .headerMobile-right .open-menu-btn .line {
    height: 2px;
    width: 30px;
    background-color: hsl(0, 0%, 100%);
    position: absolute;
  }

  .headerMobile-right .open-menu-btn .line-1 {
    transform: translateY(-8px);
  }

  .headerMobile-right .open-menu-btn .line-3 {
    transform: translateY(8px);
  }

  .navbar-logo {
    max-height: 50px;
  }

  .headerMobile .top-banner img:last-child {
    display: none;
  }

  .headerMobile__language-toggle {
    display: inline-flex;
    align-items: center;
    background-color: #3a4839;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
    font-size: 0.7em;
    font-weight: bold;
    padding: 5px 40px;
  }

  .headerMobile__language-toggle-item {
    color: #fff;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
  }

  .headerMobile__language-toggle-item--active {
    background-color: #d9534f;
    color: #fff;
    border-radius: 15px;
  }

  .headerMobile__language-toggle-item:hover:not(.headerMobile__language-toggle-item--active) {
    background-color: #324a3d;
    border-radius: 15px;
    color: #fff;
  }

  .headerMobile__language-toggle span {
    color: #fff;
    padding: 5px 5px;
  }

  .headerMobile .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100%;
  }

  .header-logo {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .top-banner img:first-child {
    margin-left: 35px;
    max-height: 70px;
  }

  .open-menu-btn,
  .headerMobile__language-toggle {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .open-menu-btn,
  .headerMobile__language-toggle {
    flex-shrink: 0;
  }

  .headerMobile .top-banner img:first-child {
    order: 1;
    margin-left: 0;
    max-height: 50px;
  }

  .container img {
    max-height: 60px;
  }

  .mobile-search-input {
    background-color: #3a4839;
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
  }

  .mobile-search-input {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }

  .mobile-search-input::placeholder {
    color: #ffffff;
    opacity: 1;
  }
}

@media (min-width: some-large-width) {
  .menu {
    justify-content: space-around;
  }
}
