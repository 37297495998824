.fiva-description-container0 {
  font-family: Arial, sans-serif;
  max-width: 1220px;
  margin: 40px auto;
  padding: 30px;
  background-color: #f7f7f7;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.fiva-description-title0 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: #333;
}

.conditions-section {
  font-size: 24px;
  font-weight: bold;
  color: #b34444;
  margin-bottom: 20px;
}

.fiva-description-container0 p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: justify;
}

.conditions-remark {
  font-size: 14px;
  margin-top: 20px;
  font-style: italic;
}

.certification-button {
  background-color: #004482;
  color: white;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.certification-button:hover {
  background-color: #003366;
}

@media (max-width: 768px) {
  .certification-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
