.vehicles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}
.vehicles-list-title {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 50px;
}

.vehicle-card {
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.vehicle-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.vehicle-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.vehicle-card h2 {
  font-size: 1em;
  margin: 0.5em;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 5px;
}

.vehicle-card a {
  display: block;
  text-align: center;
  padding: 10px 0;
  background: #3a4839;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 4px solid #b3444446;
}

.vehicle-card a:hover {
  background: #3a4839e3
}

@media (max-width: 768px) {
  .vehicles-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .vehicles-list-title {
    margin-top: 100px;
  }
}
