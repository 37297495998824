.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
}

.main-header {
  width: 100%;
}
.language-toggle {
  cursor: pointer;
  user-select: none;
}

.language-toggle span.active {
  font-weight: bold;
  color: #ff0000;
}

.language-toggle span {
  font-size: 15px;

  transition: color 0.3s ease;
}

.language-toggle span:hover:not(.active) {
  color: #333;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.navbar-scrolled {
  background-color: #fff;
}
.login-form-content {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  padding: 0px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 5px;
}
.container {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  max-width: 1700px;
}
.login-form-container {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  transition: top 0.3s;
  padding: 20px;
}

.login-form-container.active {
  top: 0;
}

.login-form-container form {
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.login-form-container form h3 {
  font-size: 24px;
  color: #3a4839;
  margin-bottom: 30px;
  text-align: center;
}

.login-form-container form .box {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #3a4839;
  border-radius: 5px;
}

.login-form-container form .btn {
  width: 100%;
  background: #3a4839;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.3s ease;
}

.login-form-container form .btn:hover {
  background: #555;
}

.login-form-container form p {
  margin-top: 20px;
  text-align: center;
}

.login-form-container form p a {
  color: #3a4839;
  text-decoration: none;
}

.login-form-container form p a:hover {
  text-decoration: underline;
}

.login-form-container form .buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.login-form-container form .buttons .btn {
  flex-basis: 48%;
  padding: 10px;
  background: #3a4839;
  color: #ffffff;
  text-align: center;
}

.login-form-container form .buttons .btn:hover {
  background: #e7e7e7;
}

#close-login-form {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
}

#login-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

#login-btn .btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

#login-btn .fa-user {
  font-size: 1.5em;
  color: #fff;
}

#login-btn .far.fa-user {
  font-size: 1.5rem;
  color: #fff;
}

.navbar-logo {
  height: auto;
  max-height: 50px;
}

/* header */
.header {
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  background-color: #3a4839;
  z-index: 1000;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.685);
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.header .logo img {
  vertical-align: middle;
}
.header .menu .head {
  display: none;
}
.header .menu ul {
  list-style: none;
}
.header .menu > ul > li {
  display: inline-block;
}
.header .menu > ul > li:not(:last-child) {
  margin-right: 40px;
}
.header .menu .dropdown {
  position: relative;
}
.header .menu a {
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 13px;
  color: hsl(0, 0%, 100%);
  line-height: 1.5;
  display: block;
}
.header .menu > ul > li > a {
  padding: 16px 0;
}
.header .menu > ul > .dropdown > a {
  padding-right: 15px;
}
.header .menu i {
  font-size: 10px;
  pointer-events: none;
  user-select: none;
  position: absolute;
  color: hsl(0, 0%, 100%);
  top: calc(50% - 5px);
}
.header .menu > ul > li > i {
  right: 0;
}
.header .menu .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  padding: 15px 0;
  background-color: #5d5d5d;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);

  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.5);
  z-index: 1;
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  opacity: 0;
}
.header-right .open-menu-btn {
  display: none;
}
.header .menu .sub-menu-right {
  left: 100%;
  top: 0;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);
}
.header .menu .sub-menu-left {
  top: 0;
  left: auto;
  right: 100%;
  background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);
}
.header .menu li:hover > .sub-menu {
  background-color: #3a4839;
  opacity: 1;
  transform: none;
  visibility: visible;
  transition: all 0.5s ease;
}
.header .menu .sub-menu a {
  padding: 6px 24px;
}
.header .menu .sub-menu .dropdown > a {
  padding-right: 34px;
}
.header .menu .sub-menu span {
  background-image: linear-gradient(hsl(0, 0%, 100%), hsl(0, 0%, 100%));
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size 0.5s ease;
}

.header .menu .sub-menu li:hover > a > span {
  background-size: 100% 1px;
}
.header .menu .sub-menu i {
  transform: rotate(-90deg);
  right: 24px;
}

.menu.flex {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 0 1em;
}

.menu.open {
  display: block;
  padding: 0;
}

@media (max-width: 1300px) {
  .header .menu > ul > li:not(:last-child) {
    margin-right: 30px;
  }

  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 70px;
  }

  .top-banner img:last-child {
    max-height: 70px;
  }
}
@media (max-width: 1243px) {
  .header .menu > ul > li:not(:last-child) {
    margin-right: 25px;
  }

  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 70px;
  }

  .top-banner img:last-child {
    max-height: 70px;
  }
  .search-btn img {
    width: 21px;
    height: auto;
  }
  .search-bar img {
    width: 21px;
    height: auto;
  }
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: #ffffff;
    margin: 0 20px;
  }
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 22px;
  }
}
@media (max-width: 1183px) {
  .header .menu > ul > li:not(:last-child) {
    margin-right: 18px;
  }

  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 70px;
  }

  .top-banner img:last-child {
    max-height: 70px;
  }
  .search-btn img {
    width: 18px;
    height: auto;
  }
  .search-bar img {
    width: 18px;
    height: auto;
  }
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: #ffffff;
    margin: 0 20px;
  }
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
}
@media (max-width: 1125px) {
  .header .menu a {
    font-size: 12px;
  }
  .header .menu > ul > li:not(:last-child) {
    margin-right: 15px;
  }
  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 60px;
  }

  .top-banner img:last-child {
    max-height: 60px;
  }
  .search-btn img {
    width: 20px;
    height: auto;
  }
  .search-bar img {
    width: 20px;
    height: auto;
  }
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: #ffffff;
    margin: 0 20px;
  }
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 21px;
  }
}
@media (max-width: 1064px) {
  .header .menu a {
    font-size: 11px;
  }
  .header .menu > ul > li:not(:last-child) {
    margin-right: 15px;
  }
  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 50px;
  }

  .top-banner img:last-child {
    max-height: 50px;
  }
  .search-btn img {
    width: 18px;
    height: auto;
  }
  .search-bar img {
    width: 18px;
    height: auto;
  }
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: #ffffff;
    margin: 0 20px;
  }
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
}
@media (max-width: 1006px) {
  .header .menu a {
    font-size: 11px;
  }
  .header .menu > ul > li:not(:last-child) {
    margin-right: 12px;
  }
  .header .menu .sub-menu {
    min-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-banner img:first-child {
    max-height: 40px;
  }

  .top-banner img:last-child {
    max-height: 40px;
  }
  .search-btn img {
    width: 18px;
    height: auto;
  }
  .search-bar img {
    width: 18px;
    height: auto;
  }
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: #ffffff;
    margin: 0 20px;
  }
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
}

@media (max-width: 1300px) {
  .header {
    padding: 12px 0;
  }
  .header .menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #5d5d5d;
    padding: 15px 30px 30px;
    overflow-y: auto;
    z-index: 1;
    transform: translateX(100%);
    background-image: url(https://www.cpaa.pt/wp-content/uploads/2021/04/fundo-scaled.jpg);
  }

  .header .menu > ul {
    padding-left: 0;
    width: 100%;
  }
  .header .menu.open {
    transform: none;
  }
  .header .menu > ul > li {
    padding-left: 0;
  }
  .header .menu .head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;
  }
  .header .menu .close-menu-btn {
    height: 35px;
    width: 35px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }
  .header .menu .close-menu-btn::before,
  .header .menu .close-menu-btn::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: hsl(0, 0%, 100%);
  }
  .header .menu .close-menu-btn::before {
    transform: rotate(45deg);
  }
  .header .menu .close-menu-btn::after {
    transform: rotate(-45deg);
  }
  .header .menu > ul > li {
    display: block;
  }
  .header .menu > ul > li:not(:last-child) {
    margin-right: 0;
  }
  .header .menu li {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  }
  .header-right > * {
    display: none;
  }
  .header .menu li:first-child {
    border-top: 1px solid hsla(0, 0%, 100%, 0.25);
  }
  .header .menu > ul > li > a {
    padding: 12px 0;
  }
  .header .menu > ul > .dropdown > a {
    padding-right: 34px;
  }
  .header .menu i {
    height: 34px;
    width: 34px;
    border: 1px solid hsla(0, 0%, 100%, 0.25);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;
    top: 7px;
  }
  .header .menu .dropdown.active > i {
    background-color: hsla(0, 0%, 100%, 0.25);
    transform: rotate(180deg);
  }
  .header .top-banner,
  .header-right {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header .menu .sub-menu {
    position: static;
    opacity: 1;
    transform: none;
    visibility: visible;
    padding: 0;
    transition: none;
    box-shadow: none;
    width: 100%;
    display: none;
  }
  .header .menu .dropdown.active > .sub-menu {
    display: block;
  }
  .header .menu .sub-menu li:last-child {
    border: none;
  }
  .header .menu .sub-menu a {
    padding: 12px 0 12px 15px;
  }
  .header .menu .sub-menu .sub-menu a {
    padding-left: 30px;
  }
  .header .menu .sub-menu .sub-menu .sub-menu a {
    padding-left: 45px;
  }
  .header .menu .sub-menu span {
    background-image: none;
  }
  .header .menu .sub-menu i {
    transform: none;
    right: 0;
  }
  .header-right .open-menu-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 44px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
  }
  .header-right .open-menu-btn .line {
    height: 2px;
    width: 30px;
    background-color: hsl(0, 0%, 100%);
    position: absolute;
  }
  .header-right .open-menu-btn .line-1 {
    transform: translateY(-8px);
  }
  .header-right .open-menu-btn .line-3 {
    transform: translateY(8px);
  }
  .navbar-logo {
    height: auto;
    max-height: 50px;
  }
  .header .top-banner img:last-child {
    display: none;
  }
  .header .container {
    display: flex;
    justify-content: space-between;
  }
  .header .top-banner img:first-child {
    order: 1;
    margin-left: 0;
    max-height: 50px;
  }
}
/* hero */
.hero {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}
@media (min-width: some-large-width) {
  .menu {
    justify-content: space-around;
  }
}
