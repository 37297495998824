.animated-news-section {
  max-width: 1220px;
  margin: 0 auto;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.animated-boxes-container1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px;
}

.card-container1 {
  flex: 0 0 calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.image-container {
  width: 100%;
  height: 223px;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-container1:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.text-content1 {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card-container1 h3 {
  font-size: 1rem;
  color: #333;
}
.card-container1 button {
  align-self: center;
  background-color: transparent;
  border: 2px solid #3a4839;
  border-radius: 20px;
  padding: 8px 16px;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #3a4839;
}

.card-container1 button:hover {
  background-color: #3a4839;
  color: #fff;
  border-color: #3a4839;
}

.more-news-button-container {
  margin-top: 40px;
  text-align: center;
  padding: 20px 0;
}

.more-news-button {
  padding: 12px 30px;
  font-size: 1em;
  color: #3a4839;
  background-color: transparent;
  border: 2px solid #333;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
}

.more-news-button:hover {
  color: white;
  background-color: #3a4839;
}

@media (max-width: 1200px) {
  .card-container1 {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .card-container1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .image {
    height: auto;
  }

  .card-container1 h3 {
    font-size: 18px;
  }

  .text-content1 {
    padding: 15px;
  }

  .card-container1 button {
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .animated-news-section,
  .animated-boxes-container1 {
    padding: 10px;
  }

  .more-news-button-container {
    padding-right: 10px;
  }
}
