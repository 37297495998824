.privacy-policy-container {
  max-width: 1220px;
  margin: auto;
  padding: 20px;
  background: #ffffff;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  margin-top: 50px;
}

.privacy-policy-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000000;
  text-align: center;
}

.privacy-policy-subtitle {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #b34444;
}

.privacy-policy-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.privacy-policy-container p,
.privacy-policy-container li {
  margin-bottom: 0.75rem;
}

.privacy-policy-container a {
  color: #b34444;
  text-decoration: underline;
}

.privacy-policy-container a:hover {
  color: #551a8b;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 10px;
  }
  .privacy-policy-title {
    font-size: 2rem;
  }
  .privacy-policy-subtitle {
    font-size: 1.25rem;
  }
  .privacy-policy-section-title {
    font-size: 1rem;
  }
}
