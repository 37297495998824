.fiva-description-container-description {
  max-width: 1220px;
  margin: 40px auto;
  font-family: "Georgia", serif;
  color: #333;
  background: #fff;
  padding: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.fiva-description-title-C {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 50px;
}
.fiva-description-container-description h2 {
  color: #b34444;
}

.fiva-description-container-description p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .fiva-description-title-C {
    font-size: 24px;
  }
}
